import { Heart, X, ZoomIn } from 'lucide-react'
import React, { useRef, useState } from 'react'
import styles from './DraggableModal.module.scss'

const DraggableModal = ({ onClose, data, openModal }) => {
	const modalRef = useRef(null)
	// const [isDragging, setIsDragging] = useState(false)
	const [position, setPosition] = useState({ x: 0, y: 0 })

	// const handlePlanZoom = () => {
	// 	const content = (
	// 		<div className={styles.planZoom}>
	// 			<img src={'/Demo/plane.jpg'} alt='' />
	// 		</div>
	// 	)
	// 	openModalWithData('План', React.cloneElement(content))
	// }

	const handleModal = src => {
		openModal(
			'Пример',
			<div className={styles.planZoom}>
				<img src={'/Demo/plane.jpg'} alt='' />
			</div>
		)
	}

	// const [initialMousePosition, setInitialMousePosition] = useState({
	// 	x: 0,
	// 	y: 0,
	// })
	// const [showExtraBlock, setShowExtraBlock] = useState(false)

	// const handleMouseDown = e => {
	// 	setIsDragging(true)
	// 	setInitialMousePosition({
	// 		x: e.clientX - position.x,
	// 		y: e.clientY - position.y,
	// 	})
	// }

	// const handleMouseMove = e => {
	// 	if (isDragging) {
	// 		setPosition({
	// 			x: e.clientX - initialMousePosition.x,
	// 			y: e.clientY - initialMousePosition.y,
	// 		})
	// 	}
	// }

	// const handleMouseUp = () => {
	// 	setIsDragging(false)
	// }

	// useEffect(() => {
	// 	if (isDragging) {
	// 		document.addEventListener('mousemove', handleMouseMove)
	// 		document.addEventListener('mouseup', handleMouseUp)
	// 	} else {
	// 		document.removeEventListener('mousemove', handleMouseMove)
	// 		document.removeEventListener('mouseup', handleMouseUp)
	// 	}

	// 	return () => {
	// 		document.removeEventListener('mousemove', handleMouseMove)
	// 		document.removeEventListener('mouseup', handleMouseUp)
	// 	}
	// }, [isDragging])

	return (
		<div
			ref={modalRef}
			className={styles.modal}
			style={{
				top: `${position.y}px`,
				right: `${position.x}px`,
			}}
		>
			<div className='w-[38%] bg-black/50 backdrop-blur-lg shadow-lg p-3'>
				<div className='flex items-center justify-between border-b border-white/20 pb-4'>
					<div className='flex items-center gap-2'>
						<Heart className='text-red-500 w-6 h-6' />
						<h2 className='text-xl font-bold text-white'>#{data.id}</h2>
					</div>
					<button
						onClick={onClose}
						className='text-gray-400 hover:text-gray-200 transition-colors'
					>
						<X className='w-6 h-6' />
					</button>
				</div>
				<div className='py-4 space-y-4 text-white'>
					<div className='flex justify-between border-b border-white/20 pb-2'>
						<p className='text-gray-300'>Этаж:</p>
						<p className='font-medium'>{data.floor}</p>
					</div>
					<div className='flex justify-between border-b border-white/20 pb-2'>
						<p className='text-gray-300'>Комнаты:</p>
						<p className='font-medium'>{data.rooms}</p>
					</div>
					<div className='flex justify-between border-b border-white/20 pb-2'>
						<p className='text-gray-300'>
							Площадь, м<sup>2</sup>:
						</p>
						<p className='font-medium'>{data.surface}</p>
					</div>
					<div className='flex justify-between border-b border-white/20 pb-2'>
						<p className='text-gray-300'>Доступность:</p>
						<p className='font-medium'>{data.availability ? 'Да' : 'Нет'}</p>
					</div>
					<div className='flex justify-between text-lg font-bold'>
						<p>Цена, млн. ₽:</p>
						<p>{data.price}</p>
					</div>
				</div>
				<div className='w-full flex justify-center mt-4'>
					<a
						href='https://www.vtb.ru/personal/ipoteka/ipotechnyj-kalkulyator/'
						target='_blank'
						rel='noopener noreferrer'
						className='w-[80%] px-4 py-2 bg-white/30 text-white font-medium rounded-lg hover:bg-white hover:text-black transition text-center'
					>
						Рассчитать ипотеку
					</a>
				</div>
			</div>

			<div className={styles.extraBlock}>
				<div className={styles.headExtra}>
					<div className={styles.line}></div>
					<div className={styles.title}>
						<h3>План квартиры</h3>
					</div>
					{/* <button
						onClick={() => setShowExtraBlock(false)}
						className={styles.closeExtra}
					>
						<ChevronLeft />
					</button> */}
				</div>
				<div className={styles.contentExtra}>
					<img src={'/Demo/plane.jpg'} alt='' />
					<button
						className={styles.btnZoom}
						onClick={() => handleModal('/Demo/plane.jpg')}
					>
						<ZoomIn />
					</button>
				</div>
			</div>
		</div>
	)
}

export default DraggableModal
