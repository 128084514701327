import styled from 'styled-components'
import { Tags } from '../../dummy'

const Toggle = styled.div`
	height: 100%;
	width: 30px;
	background: rgb(245, 101, 12);
	right: 0;
	border-radius: 8px 0 0 8px;
	position: absolute;
	color: #1a1717;
	text-align: center;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	writing-mode: tb;
	transform: rotate(180deg);
	cursor: pointer;
	justify-content: center;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 0 10px 10px;
		border-color: transparent transparent transparent rgb(245, 101, 12); /* Цвет треугольника */

		transform: ${props =>
			props.isOpen ? 'translateY(-50%)' : 'translateY(50%)'};
		rotate: ${props => (props.isOpen ? '0deg' : '180deg')};
		left: ${props => (props.isOpen ? '30px' : '0')};
		right: ${props => (props.isOpen ? '' : '30px')};
	}
`

const PortfolioPanel = styled.div`
	position: absolute;
	top: 0;
	left: 30px;
	transform: ${props =>
		props.isOpen ? 'translateX(calc(0% - 30px))' : 'translateX(-100%)'};
	width: 40%;
	height: 100%;
	background: rgb(38, 38, 38);
	color: #ffffff;
	border-radius: 0 8px 8px 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	transition: transform 0.5s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	z-index: 10;

	@media (max-width: 768px) {
		width: 90%;
	}
`

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	width: 100%;
	flex-direction: column;
	height: 100%;
	padding: 20px;
	gap: 20px;
	align-items: flex-start;
`

const FilterCheckbox = styled.div`
	display: flex;
	align-items: center;
	margin-right: 20px;

	input[type='checkbox'] {
		appearance: none;
		min-width: 20px;
		height: auto;
		aspect-ratio: 1 / 1;
		border: 2px solid;
		border-radius: 4px;
		margin-right: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: border-color 0.3s ease;

		&:hover {
			border-color: #f56512;
		}
	}
`

const PortfolioPanelContent = ({
	children,
	disabled = false,
	onClick,
	isOpen,
	selectedFilter,
	onFilterChange,
}) => {
	return (
		<PortfolioPanel isOpen={isOpen} disabled={disabled}>
			<Toggle isOpen={isOpen} onClick={!disabled ? onClick : undefined}>
				{children}
			</Toggle>
			<FilterContainer>
				{Tags.map((tag, index) => (
					<RenderTag
						key={tag.name}
						tag={tag}
						index={index}
						selectedFilter={selectedFilter}
						onFilterChange={onFilterChange}
					/>
				))}
			</FilterContainer>
		</PortfolioPanel>
	)
}

const RenderTag = ({ tag, selectedFilter, onFilterChange, index }) => {
	const isSelected =
		selectedFilter === tag.name || (index === 0 && selectedFilter === null)

	return (
		<FilterCheckbox>
			{/* <input
				type='checkbox'
				checked={isSelected}
				onChange={() => onFilterChange(isSelected ? null : tag.name)}
			/>
			<p>{tag.name}</p> */}
			<div className='form-control'>
				<label className='cursor-pointer label'>
					<input
						type='checkbox'
						checked={isSelected}
						onChange={() => onFilterChange(tag.name)}
						className='checkbox checkbox-warning'
					/>
					<span className='label-text text-white'>{tag.name}</span>
				</label>
			</div>
		</FilterCheckbox>
	)
}

export default PortfolioPanelContent
