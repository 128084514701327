import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import DraggableModal from '../../components/DraggableModal/DraggableModal'
import { ApartsId } from '../../dummy'
import styles from './images.module.scss'

export const PoligonSvg = ({
	openModal,
	svgId,
	svgContent,
	roomsFilter,
	costRange,
	surfaceRange,
	floorRange,
	availableFilter,
	activeTab,
}) => {
	const [svgEl, setSvgEl] = useState({})
	const [isModalOpen, setIsModalOpen] = useState(false)
	const svgData = ApartsId.find(item => item.id === svgId)
	const svgContainerRef = useRef(null)

	const handleSvgElementClick = event => {
		if (event.target.classList.contains(styles.disable)) {
			return
		}
		const foundElement = svgData.elements.find(element =>
			event.target.id.includes(element.id)
		)
		setSvgEl(foundElement)
		setIsModalOpen(true)
	}
	const handleModalClose = () => {
		setIsModalOpen(false)
	}

	useEffect(() => {
		if (svgContent && svgContainerRef.current && activeTab === 'filters') {
			// Теперь можно добавлять обработчики событий к элементам внутри SVG
			const svgElements = svgContainerRef.current.querySelectorAll('svg *') // Выбираем все элементы внутри SVG

			svgElements.forEach(element => {
				if (!element.classList.contains(styles.disable)) {
					element.addEventListener('click', handleSvgElementClick)
				}
			})

			// Очистка обработчиков при удалении компонента
			return () => {
				svgElements.forEach(element => {
					element.removeEventListener('click', handleSvgElementClick)
				})
			}
		}
	}, [svgContent, activeTab])

	useEffect(() => {
		const isWithinRange = (value, [min, max]) => value >= min && value <= max
		if (svgContent && svgContainerRef.current && svgData != undefined) {
			svgData.elements.forEach(element => {
				const svgElements = svgContainerRef.current.querySelectorAll(
					`[id*="${element.id}"]`
				)

				if (svgElements.length > 0) {
					if (!element.available) {
						svgElements[1].classList.add(styles.disable)
					} else {
						svgElements[1].classList.remove(styles.disable)
					}

					if (
						(roomsFilter.length === 0 || roomsFilter.includes(element.rooms)) &&
						(availableFilter === null ||
							element.available === availableFilter) &&
						isWithinRange(element.price, costRange) &&
						isWithinRange(element.surface, surfaceRange) &&
						isWithinRange(element.floor, floorRange)
					) {
						svgElements[1].classList.remove(styles.invisible)
					} else {
						svgElements[1].classList.add(styles.invisible)
					}
				}
			})
		}
	}, [
		svgContent,
		svgId,
		svgData,
		roomsFilter,
		availableFilter,
		costRange,
		surfaceRange,
		floorRange,
	])

	if (!svgContent) {
		return <div>Loading...</div>
	}

	return (
		<>
			<div
				ref={svgContainerRef}
				style={{ display: 'contents' }}
				className={classNames(
					styles.svgContainer,
					activeTab === 'filters' && styles.active
				)}
				dangerouslySetInnerHTML={{ __html: svgContent }}
			/>
			{isModalOpen && (
				<DraggableModal
					onClose={handleModalClose}
					openModal={openModal}
					data={svgEl}
				/>
			)}
		</>
	)
}
