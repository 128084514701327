import { useEffect } from 'react'
import styled from 'styled-components'
import { Close } from '../../Icons'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6); // затемнение фона
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 140px;
	z-index: 1000;
	overflow: hidden; // чтобы модалка не залезала под хедер
`
const ModalContainer = styled.div`
	position: relative;
	background-color: rgb(45, 45, 45);
	border-radius: 8px;
	padding: 20px;
	max-width: 90vw; // адаптивная ширина
	max-height: calc(
		90vh - 140px
	); // адаптивная высота, чтобы не уходило за экран
	overflow-y: auto; // если контента много - появляется скролл
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	z-index: 1001;
	display: flex;
	flex-direction: column;
	animation: slideIn 0.3s ease-in-out;

	// Адаптивные размеры для небольших экранов
	@media (max-width: 768px) {
		max-width: 95vw;
		max-height: 80vh;
		padding: 15px;
	}

	@keyframes slideIn {
		from {
			transform: translateY(-20px);
			opacity: 0;
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@media (min-aspect-ratio: 1/1) {
		max-width: 60vw; // Уменьшаем максимальную ширину
		max-height: 80vw; // Увеличиваем максимальную высоту для сохранения квадратной формы
	}
`
const CloseButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;

	svg {
		width: 30px;
		height: 30px;
		fill: #fff;
	}
`

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	justify-content: center;
	align-items: center;
	padding: 25px;

	img {
		max-width: 100%;
		max-height: 80vh;
		object-fit: contain;
	}
`

const ModalHeader = styled.h2`
	margin: 0;
	font-size: 2rem;
	text-align: center;

	color: #ffffff;
`

const ModalBody = styled.div`
	width: 100%;
	text-align: left;
`

const Modal = ({ isOpen, onClose, title, children }) => {
	useEffect(() => {
		isOpen && (document.body.style.overflow = 'hidden')
		return () => {
			document.body.style.overflow = ''
		}
	}, [isOpen])
	if (!isOpen) return null

	return (
		<Overlay onClick={onClose}>
			<ModalContainer onClick={e => e.stopPropagation()}>
				<CloseButton onClick={onClose}>
					<Close />
				</CloseButton>
				<ModalContent>
					<ModalHeader>{title}</ModalHeader>
					<ModalBody>{children}</ModalBody>
				</ModalContent>
			</ModalContainer>
		</Overlay>
	)
}

export default Modal
